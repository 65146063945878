var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{ref:"table",attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":"begin","sort-desc":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"ml-4"},[_vm._v("Ausleihen")])]},proxy:true},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/rental/" + (item.id))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}},{key:"item.begin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.begin).toLocaleString('de'))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [(item.end != null)?_c('span',[_vm._v(" "+_vm._s(new Date(item.end).toLocaleString('de'))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.key.lock.location",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/location/" + (item.key.lock.location.id))}},[_vm._v(_vm._s(item.key.lock.location.name))])]}},{key:"item.key.lock",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/lock/" + (item.key.lock.id))}},[_vm._v(_vm._s(item.key.lock.name))])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/key/" + (item.key.id))}},[_vm._v(_vm._s(item.key.number))])]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/user/" + (item.user.id))}},[_vm._v(_vm._s(item.user.name))])]}},{key:"item.issuing_user.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/user/" + (item.issuing_user.id))}},[_vm._v(_vm._s(item.issuing_user.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(item.active)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openReturnPrompt(item)}}},on),[_vm._v(" mdi-arrow-u-left-bottom ")])]}}],null,true)},[_c('span',[_vm._v("Schlüssel zurückgeben")])]):_vm._e()],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.returnDialog),callback:function ($$v) {_vm.returnDialog=$$v},expression:"returnDialog"}},[_c('v-card',{staticClass:"pb-1"},[_c('v-card-title',[_vm._v("Schlüssel "+_vm._s(_vm.rentalInDialog.key.number)+" wirklich zurückgeben?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.returnItem}},[_c('v-icon',{attrs:{"left":"","size":"24"}},[_vm._v("mdi-check")]),_vm._v(" Bestätigen ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }