var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{ref:"table",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"multi-sort":"","sort-by":['location.name', 'name'],"loading-text":"Lade Daten..."},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"ml-4"},[_vm._v("Schlösser")])]},proxy:true},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/lock/" + (item.id))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}},{key:"item.location.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/location/" + (item.location.id))}},[_vm._v(_vm._s(item.location.name))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/lock/" + (item.id))}},[_vm._v(_vm._s(item.name))])]}},{key:"item.available_keys",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount_free_keys)+" / "+_vm._s(item.amount_keys)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDeletePrompt(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pb-1"},[_c('v-card-title',[_vm._v("Schloss "+_vm._s(_vm.lockToDeleteName)+" wirklich löschen?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.deleteItem}},[_c('v-icon',{attrs:{"left":"","size":"24"}},[_vm._v("mdi-delete")]),_vm._v(" Bestätigen ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }