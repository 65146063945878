








  import Vue from 'vue'

  export default Vue.extend ({
  name: "AppTitle",
  props: ['viewTitle']
});
