











import Vue from 'vue';
import AppHeader from "@/components/AppHeader.vue";
import AuthService from "@/services/AuthService";

export default Vue.extend({
  name: 'App',
  components: {AppHeader},
  data: () => ({
    //
  }),
  mounted() {
    this.$vuetify.theme.currentTheme.secondary = '#41A6A0';

    if (AuthService.isLoggedIn()) {
      AuthService.refreshUser();
    }
  }
});
