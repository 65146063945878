var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{ref:"table",attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":"begin","sort-desc":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"ml-4"},[_vm._v("Ausleihen")])]},proxy:true},{key:"item.begin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.begin).toLocaleString('de'))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [(item.end != null)?_c('span',[_vm._v(" "+_vm._s(new Date(item.end).toLocaleString('de'))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.key.lock.location",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/location/" + (item.key.lock.location.id))}},[_vm._v(_vm._s(item.key.lock.location.name))])]}},{key:"item.key.lock",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/lock/" + (item.key.lock.id))}},[_vm._v(_vm._s(item.key.lock.name))])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/key/" + (item.key.id))}},[_vm._v(_vm._s(item.key.number))])]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/user/" + (item.user.id))}},[_vm._v(_vm._s(item.user.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/rental/" + (item.id))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }